import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Image from "../image"
import Popover from "@material-ui/core/Popover"
import { makeStyles } from "@material-ui/core/styles"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import useMedia from "../customHooks/useMedia"

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: "rgba(195, 208, 237, 0.6)",
  },
}))

const Header = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const imageSize = 35

  const isMobile = useMedia("(max-width: 767px)")

  return (
    <header>
      <NavWrap>
        <Title>
          <Link to="/">
            <TitleText>フニオチル</TitleText>
            <Image
              filename={`title.png`}
              style={{ width: isMobile ? 115 : 175 }}
            />
          </Link>
        </Title>
        {/* {isMobile ? (
          <DrawerMenu
            imageSize={imageSize}
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
          />
        ) : (
          <Menu
            imageSize={imageSize}
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
          />
        )} */}

        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <p>Coming Soon.</p>
        </Popover>
      </NavWrap>
    </header>
  )
}
export default Header

const NavWrap = styled.nav`
  display: flex;
  flex-direction: row;
  height: 100px;
  background: rgb(195, 208, 237);
  background: linear-gradient(
    180deg,
    rgba(195, 208, 237, 1) 0%,
    rgba(255, 255, 255, 1) 60%
  );
`

const Title = styled.div`
  margin: 15px auto 0 40px;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    margin: 15px auto 0 15px;
  }
`

const TitleText = styled.span`
  font-size: 1.25rem;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    font-size: 0.875rem;
  }
`

const Span = styled.span`
  font-size: 0.875rem;
`

const Menu = ({ imageSize, handlePopoverOpen, handlePopoverClose }) => (
  <MenuList>
    <MenuChild>
      <Link to="/about/">
        <Span>About</Span>
      </Link>
    </MenuChild>
    <MenuChild>
      <a href="https://pintcle-jodo.netlify.app" target="_blank">
        <Span>Archive</Span>
      </a>
      {/* <Link to="/archieve/"> */}
      {/* <Span
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        Archive
      </Span> */}
      {/* </Link> */}
    </MenuChild>
    {/* <MenuChild>
      <Icon href="http://juggling-pintcle.com/" target="_brank" rel="noopener">
        <Image
          filename={`pintcle.jpg`}
          style={{
            width: imageSize,
            height: imageSize,
            borderRadius: "50%",
          }}
        />
      </Icon>
    </MenuChild>
    <MenuChild>
      <Icon href="https://scrapbox.io/pintcle/" target="_brank" rel="noopener">
        <Image
          filename={`scrapbox.png`}
          style={{
            width: imageSize,
            height: imageSize,
            borderRadius: "50%",
          }}
        />
      </Icon>
    </MenuChild>
    <MenuChild>
      <Icon
        href="https://twitter.com/jugglingpintcle"
        target="_brank"
        rel="noopener"
      >
        <Image
          filename={`twitter.png`}
          style={{
            width: imageSize,
            height: imageSize,
            borderRadius: "50%",
          }}
        />
      </Icon>
    </MenuChild>
    <MenuChild>
      <Icon
        href="https://juggling-mstdn.net/@pintcle"
        target="_brank"
        rel="noopener"
      >
        <Image
          filename={`icons8-mastodon-64.png`}
          style={{
            width: imageSize,
            height: imageSize,
            borderRadius: "50%",
          }}
        />
      </Icon>
    </MenuChild> */}
  </MenuList>
)

const MenuList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 40px 0 auto;
`
const MenuChild = styled.li`
  margin: 0 10px;
  &:hover {
    opacity: 0.6;
    transition-duration: 0.3s;
  }
`

const DrawerMenu = ({ imageSize, handlePopoverOpen, handlePopoverClose }) => {
  const [drawerState, setDrawerState] = React.useState(false)

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return
    }

    setDrawerState(open)
  }

  return (
    <>
      <Hamburger onClick={toggleDrawer(!drawerState)}>
        <a className="menu-trigger" href="#">
          <span></span>
          <span></span>
          <span></span>
        </a>
      </Hamburger>

      <SwipeableDrawer
        anchor={"top"}
        open={drawerState}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <DrawerList>
          <DrawerChild>
            <Link to="/about/">
              <Span>About</Span>
            </Link>
            <Hr />
          </DrawerChild>
          <DrawerChild>
            <a href="https://pintcle-jodo.netlify.app" target="_blank">
              <Span>Archive</Span>
            </a>
            {/* <Link to="/archieve/"> */}
            {/* <Span
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              Archive
            </Span> */}
            {/* </Link> */}
            <Hr />
          </DrawerChild>
          {/* <DrawerChild>
            <Icon
              href="http://juggling-pintcle.com/"
              target="_brank"
              rel="noopener"
            >
              <Image
                filename={`pintcle.jpg`}
                style={{
                  width: imageSize,
                  height: imageSize,
                  borderRadius: "50%",
                }}
              />
            </Icon>
            <Hr />
          </DrawerChild>
          <DrawerChild>
            <Icon
              href="https://scrapbox.io/pintcle/"
              target="_brank"
              rel="noopener"
            >
              <Image
                filename={`scrapbox.png`}
                style={{
                  width: imageSize,
                  height: imageSize,
                  borderRadius: "50%",
                }}
              />
            </Icon>
            <Hr />
          </DrawerChild>
          <DrawerChild>
            <Icon
              href="https://twitter.com/jugglingpintcle"
              target="_brank"
              rel="noopener"
            >
              <Image
                filename={`twitter.png`}
                style={{
                  width: imageSize,
                  height: imageSize,
                  borderRadius: "50%",
                }}
              />
            </Icon>
            <Hr />
          </DrawerChild>
          <DrawerChild>
            <Icon
              href="https://juggling-mstdn.net/@pintcle"
              target="_brank"
              rel="noopener"
            >
              <Image
                filename={`icons8-mastodon-64.png`}
                style={{
                  width: imageSize,
                  height: imageSize,
                  borderRadius: "50%",
                }}
              />
            </Icon>
            <Hr />
          </DrawerChild>
          <DrawerChild>
            <Icon
              href="https://www.facebook.com/JugglingPintcle/"
              target="_brank"
              rel="noopener"
            >
              <Image
                filename={`facebook.png`}
                style={{
                  width: imageSize,
                  height: imageSize,
                  borderRadius: "50%",
                }}
              />
            </Icon>
            <Hr />
          </DrawerChild> */}
        </DrawerList>
      </SwipeableDrawer>
    </>
  )
}

const DrawerList = styled.ul`
  padding: 40px;
  background-color: rgba(195, 208, 237, 1);
`
const DrawerChild = styled.li`
  margin: 14px auto;
  &:hover {
    opacity: 0.6;
    transition-duration: 0.3s;
  }
`

const Icon = styled.a``

const Hr = styled.hr`
  margin-top: 14px;
  height: 1px;
  width: calc(100% - 40px);
  background-color: #9a9a9a;
  border: none;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    width: calc(90vw - 40px);
  }
`

const Hamburger = styled.div`
  margin: 20px;
`
