import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Image from "../image"
import useMedia from "../customHooks/useMedia"

const Footer = () => {
  const isMobile = useMedia("(max-width: 767px)")
  const imageSize = isMobile ? 40 : 60

  /**
   * アイコンは以下から拝借した
   * https://www.iconfinder.com/social-media-icons
   */
  return (
    <NavWrap>
      {/* <Wave /> */}

      <Contents>
        <MenuList>
          <MenuChild>
            <Icon
              href="http://juggling-pintcle.com/"
              target="_brank"
              rel="noopener"
            >
              <Image
                filename={`pintcle.jpg`}
                style={{
                  width: imageSize,
                  height: imageSize,
                  borderRadius: "50%",
                }}
              />
            </Icon>
          </MenuChild>
          <MenuChild>
            <Icon
              href="https://scrapbox.io/pintcle/"
              target="_brank"
              rel="noopener"
            >
              <Image
                filename={`scrapbox.png`}
                style={{
                  width: imageSize,
                  height: imageSize,
                  borderRadius: "50%",
                }}
              />
            </Icon>
          </MenuChild>
          <MenuChild>
            <Icon
              href="https://twitter.com/jugglingpintcle"
              target="_brank"
              rel="noopener"
            >
              <Image
                filename={`twitter2.png`}
                style={{
                  width: imageSize,
                  height: imageSize,
                  borderRadius: "50%",
                }}
              />
            </Icon>
          </MenuChild>
          <MenuChild>
            <Icon
              href="https://juggling-mstdn.net/@pintcle"
              target="_brank"
              rel="noopener"
            >
              <Image
                filename={`icons8-mastodon-64.png`}
                style={{
                  width: imageSize,
                  height: imageSize,
                  borderRadius: "50%",
                }}
              />
            </Icon>
          </MenuChild>
        </MenuList>

        <Link to="/">
          <SiteName>フニオチル</SiteName>
          <Image
            filename={`title.png`}
            style={{ width: 200, margin: "0 auto" }}
          />
        </Link>
        <CopyRight>
          &copy; {new Date().getFullYear()} Juggling Unit ピントクル
        </CopyRight>
      </Contents>
    </NavWrap>
  )
}
export default Footer

const NavWrap = styled.footer`
  height: 100%;
`
const Contents = styled.div`
  background-color: rgba(195, 208, 237, 1);
`

const MenuList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px 0;
`

const MenuChild = styled.li`
  margin: 0 5px;
  &:hover {
    opacity: 0.6;
    transition-duration: 0.3s;
  }
`

const Icon = styled.a``

const CopyRight = styled.p`
  text-align: center;
  padding: 40px 0;
`

const SiteName = styled.div`
  font-size: 1.5rem;
  text-align: center;
`

const Wave = () => (
  <div>
    <svg
      className="waves"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28"
      preserveAspectRatio="none"
      shape-rendering="auto"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      <g className="parallax">
        <use
          xlinkHref="#gentle-wave"
          x="48"
          y="0"
          fill="rgba(195,208,237,0.3)"
        />
        <use
          xlinkHref="#gentle-wave"
          x="48"
          y="3"
          fill="rgba(195,208,237,0.2)"
        />
        <use
          xlinkHref="#gentle-wave"
          x="48"
          y="5"
          fill="rgba(195,208,237,0.1)"
        />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(195,208,237,1)" />
      </g>
    </svg>
  </div>
)
